<template>
  <div class="w-3/4 flex flex-col justify-center items-center gap-10">
    <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <div
      v-if="customer && complaint && !loading"
      class="w-full mb-4 bg-white rounded-xl shadow-lg text-gray-600"
    >
      <div
        class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer"
        @click="accordionOpen = !accordionOpen"
        style="border-bottom: gray solid 1px"
      >
        <p class="text-dark text-left"><b>Detalle de la queja</b></p>
        <span
          class="h-5 w-4 my-2 mx-2 flex justify-center items-center transition"
          :class="{ 'transform rotate-180': accordionOpen }"
        >
          <img src="@/assets/icons/arrow-down.svg" alt="" />
        </span>
      </div>
      <div v-if="accordionOpen" class="px-8 py-3">
        <h1 class="mt-2 text-2xl text-left text-blue">
          <b>Datos de contacto</b>
        </h1>
        <div v-if="customer" class="grid grid-cols-2 gap-4 w-full">
          <BaseRadio
            ref="personType"
            name="personType"
            id="personType"
            label="Tipo de persona"
            :radioOptions="formOptions.optionsPersonType"
            v-model="customer.person_type"
            :disabled="true"
          />
        </div>
        <CustomerNatural
          v-if="customer && customer.person_type == 1"
          :customer="customer"
        />
        <CustomerLegal
          v-if="customer && customer.person_type == 2"
          :customer="customer"
        />
        <Complaint :complaint="complaint" />
      </div>
      <AnnexesManagement
        :references="general_references"
        :complaint="complaint"
        v-if="customer && complaint"
        class="mt-4 px-0"
      />

      <div>
        <a-modal
          v-model="modalVisible"
          centered
          shape="round"
          :footer="null"
          :width="'60%'"
        >
          <a-row class="h-full">
            <a-col :span="24" :sm="24" :lg="{ span: 24 }" class="px-2">
              <h1 class="mt-6 text-2xl text-center text-blue">
                <b>Clasificación realizada</b>
              </h1>
              <p class="mt-8 mb-2 text-center">
                El código asignado para ingreso a SOLIP es:
              </p>
              <h2 class="text-xl text-center text-blue">
                <b>{{ code }}</b>
              </h2>
              <div class="px-16 mt-2">
                <a-button
                  class="my-6"
                  type="primary"
                  shape="circle"
                  :block="true"
                  @click="updateClassification"
                >
                  ACEPTAR Y GENERAR ARCHIVO
                </a-button>
              </div>
            </a-col>
          </a-row>
        </a-modal>
      </div>
    </div>
  </div>
</template>
  
  <script>
// #004884
import { mapState } from "vuex";
import AnnexesManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AnnexesManagement";
import Complaint from "@/components/Dashboard/Complaints/Complaint";
import CustomerNatural from "@/components/Dashboard/Customers/CustomerNatural";
import CustomerLegal from "@/components/Dashboard/Customers/CustomerLegal";
import DynamicForm from "@/components/DynamicForm/";

export default {
  components: {
    DynamicForm,
    Complaint,
    CustomerLegal,
    CustomerNatural,
    AnnexesManagement,
  },
  data() {
    return {
      document_id: null,
      code: -1,
      modalVisible: false,
      accordionOpen: true,
      accordionOpen2: true,
      selectedClasification: -1,
      optionClasification: {
        1: "complaint",
        2: "demand",
        3: "conciliation",
      },
      errorCustomer: false,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      filterSearch: "",
      general_references: "!,6,7,8,9", // 6:management, 7:answer, 8:fraud, 9:acknowledgment
      step: 2,
      showTab: -1,
      showLimit: false,
      dataTable: {
        header: [
          { title: "Nombre del CF", style: "w-20" },
          { title: "Entidad", style: "w-15" },
          { title: "Codigo interno", style: "w-15" },
          { title: "Contexto", style: "w-30" },
          { title: "Acciones", style: "w-20" },
        ],
        content: [],
      },
      initForm: {},
      loading: false,
      page: 1,
      limit: 10,
      totalPages: 1,
      dataRows: [],
      count: 0,
      pageSize: 100,
      complaint: {},
      customer: null,
      pageSizeOptions: ["50", "100", "150", "200", "250"],
    };
  },
  created() {
    this.complaintDetails();
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.updateRouterQuery();
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    "$route.query.page": function () {
      this.getData();
    },
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
  methods: {
    async updateClassification() {
      if (this.selectedClasification == -1) {
        console.log("Bad clasification");
        return;
      }
      this.complaint["classification"] =
        this.optionClasification[this.selectedClasification];
      let { data, error } = await this.$api.updateClassification(
        { classification: this.complaint["classification"] },
        `?id=${this.document_id}`
      );
      if (error) {
        console.log("error", error);
        return;
      }
      console.log({ ...this.complaint, ...{ costumer: this.customer } });
      const answ = await this.generateFileClassification();
      console.log("updateClasification", answ);
      this.$router.push({ name: "clasification-demand" });
    },
    async generateFileClassification(type_file = "xlsx") {
      this.loading = true;
      this.spinner = true;
      let { data, error } = await this.$api.postComplaintClassification(
        { ...this.complaint, ...{ costumer: this.customer } },
        `?id=${this.document_id}&get_file=${type_file}`
      );
      if (error) {
        console.log("error");
        return;
      }
      this.spinner = false;
      this.loading = false;
      const link = document.createElement("a");

      link.href = data.url;
      link.setAttribute("download", "solip_file");
      document.body.appendChild(link);

      link.click();
      await this.getData();
      this.download_in_process = false;
    },
    async complaintDetails() {
      const id = this.$route.params.id;
      this.document_id = this.$route.params.document_id;
      this.code = id;
      this.loading = true;
      let { data, error } = await this.$api.getDetailComplaint(`${id}`);
      this.loading = false;

      if (error) {
        console.log("error");
        return;
      }

      if (data) {
        const complaint = { ...data };

        complaint.archivos_apoderado = complaint?.mis_archivos?.filter(
          (item) => item.reference === "attorney"
        );
        console.log(data);
        complaint.abroad = complaint.codigo_pais == "Colombia" ? 1 : 2;
        complaint.es_apoderado = complaint.es_apoderado ? 2 : 1;
        console.log(complaint);
        const numero_id_CF = complaint.numero_id_CF;
        const tipo_id_CF = complaint.tipo_id_CF;
        let identificationType_id = 1; // Por defecto 1
        const objIdentificationType =
          this.$store.state.form.optionsIdentificationType?.filter(
            (identificationType) => identificationType.name == tipo_id_CF
          );
        if (objIdentificationType.length > 0) {
          identificationType_id = objIdentificationType[0].id;
        }
        let result = null;
        if (complaint.user_history_id) {
          result = await this.$api.getQueryPersonHistory(
            `?identification_number=${numero_id_CF}&user_history=${complaint.user_history_id}`
          );
        } else {
          result = await this.$api.getQueryPerson(
            `?identification_number=${numero_id_CF}&identification_type=${identificationType_id}`
          );
        }
        this.complaint = complaint;
        if (result.data) {
          if (result.data.results.length === 0) {
            this.customer = {
              identification_type_name: this.complaint.tipo_id_CF,
              identification_number: this.complaint.numero_id_CF,
              person_type:
                this.complaint.tipo_persona === "Natural" ? "1" : "2",
              first_name: this.complaint.nombres,
              last_name: null,
            };
          } else if (!complaint.user_history_id) {
            const user = result.data.results[0];

            this.customer = {
              identification_type_name: this.complaint.tipo_id_CF,
              identification_number: this.complaint.numero_id_CF,
              birth_date: user.birth_date,
              person_type: String(user.person_type),
              first_name: user.user_id.first_name,
              last_name: user.user_id.last_name,
              business_name: user.user_id?.business_name,
              phone: user.user_id.phone,
              email: user.user_id.email,
              departamento_cod: user.user_id?.departamento_cod,
              municipio_cod: user.user_id?.municipio_cod,
              address: user.user_id?.address,
            };
          } else if (complaint.user_history_id) {
            const user = result.data.results[0];
            this.customer = {
              identification_type_name: this.complaint.tipo_id_CF,
              identification_number: this.complaint.numero_id_CF,
              birth_date: user.birth_date,
              person_type:
                this.complaint.tipo_persona === "Natural" ? "1" : "2",
              first_name: user.first_name,
              last_name: user.last_name,
              business_name: user?.business_name,
              phone: user.phone,
              email: user.email,
              departamento_cod: user?.departamento_cod,
              municipio_cod: user?.municipio_cod,
              address: user?.address,
            };
          }
        }
        this.complaint = complaint;
        this.step = 2;
      }
      this.loading = false;
    },
    onSearch(value) {
      this.$notification.open({
        title: "Búsqueda",
        message: "Buscando..." + value,
      });
      this.filterSearch = value;
      this.getData();
    },
    async getData() {
      this.loading = true;
      let data = {};

      try {
        const search =
          this.filterSearch == "" ? "" : `&search=${this.filterSearch}`;
        const urlService1 = `/jurisdiccional-servi/complaint-classification?page=${this.page}&page_size=${this.pageSize}${search}`;
        let { error, data: dataPivot } = await this.$api.getResource(
          urlService1
        );
        if (error) {
          console.error(error);
          data = [
            {
              codigo_queja: "14221678127339437326",
              entidad: "Company Acevedo",
              nombre_cf: "Acevedo",
              texto_queja:
                "Nueva queja en la plataforma sfquesfc-quejjas-ML. Conciliación, demanda. Emita",
            },
            {
              codigo_queja: "101111111777777",
              entidad: "Company Acevedo",
              nombre_cf: "Sebastian",
              texto_queja:
                "Nueva queja en la plataforma sfquesfc-quejjas-ML. Conciliación, demanda. Emita",
            },
          ];
          this.dataRows = data;
          this.count = 200;
        } else {
          console.log(dataPivot);
          data = dataPivot.data;
          this.dataRows = dataPivot.data;
          this.count = dataPivot.count;
          console.log("count", this.count);
        }
      } catch (e) {
        console.log("Err in getData", e);
      }

      this.loading = false;
    },
    updateRouterQuery() {
      this.$router
        .replace({
          query: {
            page: this.page,
          },
        })
        .catch((error) => error);
    },
    async onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      await this.getData();
    },
    pagination(newPage) {
      if (newPage > 0 && newPage <= this.totalPages) {
        this.page = newPage;
      }
      this.getData();
    },
    currentDate() {
      // Obtener la fecha actual
      const today = new Date();

      // Obtener los componentes de la fecha
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11, por lo que se suma 1
      const day = String(today.getDate()).padStart(2, "0");

      // Crear la cadena en el formato deseado
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const files = event.target.files;

      if (files.length > 5) {
        alert("Selecciona máximo 5 archivos.");
        return;
      }

      this.files = files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.size > 1024 * 1024) {
          alert(
            "El archivo " +
              file.name +
              " excede el tamaño máximo permitido (1 MB)."
          );
          return;
        }

        console.log("Archivo seleccionado:", file);
      }
    },
    changeShowTab(index) {
      if (this.showTab === index) {
        this.showTab = -1;
      } else {
        this.showTab = index;
      }
    },
    saveRecommendation() {
      this.step = 1;
    },
    async manage(idClaim) {
      this.loading = true;
      const urlService = `/complaint_detail/${idClaim}`;
      let { data } = await this.$api.getResource(urlService);
      if (data !== undefined) {
        this.initForm = data;
      } else {
        const urlService1 = `/complaint_historical/?codigo_queja=${idClaim}`;
        let { data: data1 } = await this.$api.getResource(urlService1);
        const urlService2 = `/complaint_historical/${idClaim}/?fecha_actualizada=${
          data1.actualizaciones[data1.actualizaciones.length - 1]
            .fecha_actualizada
        }`;
        let { data: data2 } = await this.$api.getResource(urlService2);
        this.initForm = data2.data;
      }
      this.loading = false;
      this.changeStep(2);
    },
    changeReports(addReport = true, index) {
      if (addReport) {
        this.newRecommendation.reports.push({});
      } else {
        let arrayPivot = [...this.newRecommendation.reports];
        arrayPivot.splice(index, 1);
        this.newRecommendation.reports = arrayPivot;
      }
    },
    changeStep(step = 1) {
      this.step = step;
    },
    saveTemporaly() {
      this.msgButtonTemp = "Guardado!";
      setTimeout(() => {
        this.visibleButtonSaveTemp = false;
      }, 2000);
      localStorage.setItem(
        "newRecommendationFormSave",
        JSON.stringify(this.dataForm)
      );
    },
    formatStatus(status, defaultClass) {
      const obj = {
        Borrador: "btn-warning-light",
        Formulado: "btn-success-light",
        Finalizado: "btn-success",
        Cerrado: "btn-danger-light",
        Seguimiento: "btn-primary-light",
      };
      return obj[status] + " " + defaultClass;
    },
    formatDate(inputDate) {
      // Convierte la cadena de entrada en un objeto Date
      const date = new Date(inputDate);

      // Obtiene los componentes de la fecha
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Añade cero al principio si es necesario
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";

      // Calcula la hora en formato 12 horas
      const formattedHours = hours % 12 || 12;

      // Formatea la fecha en el formato deseado en inglés
      const formattedDate = `${month}/${day}/${year} ${formattedHours}:${minutes} ${ampm}`;

      return formattedDate;
    },
  },
};
</script>
  
  <style scoped>
.search-complaints .ant-table-thead > tr > th {
  text-align: center;
}

.accordion-100 > div {
  width: 100%;
}

.selection-padding-10 > div > div > div,
.selection-padding-10 > div > input {
  padding: 10px !important;
  min-height: 52px;
}

button {
  border-radius: 5px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.p-10 {
  padding: 10px;
}

.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.ms-auto {
  margin-left: auto;
}

.text-primary {
  color: #4558dc;
}

.btn-large {
  min-width: 300px;
}

.btn-small {
  min-width: 90px;
  padding: 3px;
}

.btn-white {
  box-shadow: 0 0 2mm #e7e7e7;
}

.btn-square {
  width: 40px;
  height: 40px;
}

.btn-square-small {
  width: 26px;
  height: 26px;
}

.rounded {
  border-radius: 50px;
}

div > small {
  color: #7a7a7a;
}

.rounded-border {
  cursor: pointer;
  display: flex;
  padding-top: 2px;
  padding-left: 7px;
  border-radius: 50px;
  height: 26px;
  width: 26px;
  border: 0.5px solid transparent;
  margin: 0px;
  margin-right: 8px;
}

.limit-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  display: inline-block;
  /* Asegura que el elemento sea bloque para aplicar el ancho máximo */
}

.rounded-border:hover {
  transition: 0.5s;
  color: #4558dc;
}

.rounded-border-active {
  border: 0.5px solid #4558dc;
}

.btn-gray {
  background: #bd9e9e;
  color: black;
}

.btn-primary {
  background: #4558dc;
  color: white;
}

.btn-warning {
  background: #f8b446;
  color: white;
}

.btn-danger {
  background: #ea4a76;
  color: white;
}

.btn-success {
  background: #14a171;
  color: white;
}

.btn-warning-light {
  background: #fff3e0;
  color: #f8b446;
}

.btn-danger-light {
  background: #ffedf2;
  color: #ea4a76;
}

.btn-primary-light {
  background: #edf3ff;
  color: #4558dc;
}

.btn-success-light {
  background: #e5fbf5;
  color: #14a171;
}

.no-border {
  border: 0px !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #e7e7e7;
}

.flag {
  min-width: 90px;
  display: flex;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.search > input {
  border: 1px solid;
  border-radius: 10px;
  min-width: 400px;
  margin-right: 10px;
  padding: 0px 10px;
}

.search > button {
  border-radius: 10px;
}

.table {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  flex-wrap: wrap;
}

.tableHeader {
  padding: 10px 0;
  background: #fafafa;
  font-weight: bold;
}

.tableHeader > div > h3 {
  color: #6b6b6b !important;
}

.table > div {
  flex-wrap: wrap;
}

.table > div > div {
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 1px;
}

.progressbar {
  height: 26px;
  border-radius: 5px;
  background: #e5fbf5;
  display: flex;
  justify-content: start;
  align-content: center;
  align-items: center;
}

.progressbar > div {
  height: 26px;
  color: white;
  border-radius: 5px;
  background: #14a171;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}

.w-5 {
  width: 5%;
}

.d-none {
  display: none;
}

.containerFileOptional {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed gray;
  padding: 20px;
  border-radius: 15px;
}

.containerFileOptional div {
  border-radius: 15px;
  background: rgba(51, 102, 204, var(--tw-bg-opacity));
  margin-bottom: 10px;
  color: white;
  padding: 0.5em 4em;
}

.btn-save-temporaly {
  position: fixed;
  right: 40px;
  top: 200px;
  z-index: 10000;
}

.cursor {
  cursor: pointer;
}

.contentDropdown {
  position: absolute;
  background: white;
  z-index: 1000;
  border: 1px solid #e7e7e7;
  width: 57px;
}

.contentDropdown > div {
  width: 100% !important;
}
</style>